export namespace MultipleChangeUserGameBuild {
	export const path = "/game-build-storage/multiple_change_user_build"
	export class Request {
		from: string[]
		to: string
	}
	export class ResponseSuccess {
		success: true
		countChangedUsers: number
	}
	export class ResponseError {
		statusCode: number
		message: string
	}
}

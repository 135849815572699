import { styled } from "styled-components"

export const VersionsPageWrapper = styled.div`
	.ant-table {
		max-height: 65vh !important;
		overflow-y: auto !important;
	}
	.ant-table-cell {
		padding: 10px !important;
	}
	.ant-checkbox-inner,
	.ant-checkbox-input {
		transform: scale(1.5);
	}
`
export const ControlBlock = styled.div`
	padding: 15px;
	margin-bottom: 20px;

	border: 1px rgba(0, 0, 0, 0.1) solid;
	border-radius: 10px;

	display: grid;
	row-gap: 15px;
`
export const ControlItem = styled.div`
	width: max-content;

	display: grid;
	grid-auto-flow: column;
	align-items: center;
	gap: 20px;
`
export const Title = styled.div``

export const Description = styled.div`
	white-space: pre-line;
	text-align: left;
`

export const Maket = styled(ControlItem)`
	position: relative;
	width: min-content;

	margin-top: 30px;

	padding: 20px;

	border: 1px solid orange;
	border-radius: 10px;

	& > * {
		margin: 0 20px;
		width: max-content;
	}

	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr 1fr 1fr;
	place-items: center;

	.ant-slider {
		width: 200px !important;
	}

	&::after {
		position: absolute;
		content: "Макет";
		color: orange;
		font-weight: 800;
		top: -10px;
		left: 20px;

		background-color: white;
	}
`

export const Counter = styled.div`
	display: grid;
	grid-auto-flow: column;
	width: 40px !important;
	text-align: center;
`

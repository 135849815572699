import { DeleteOutlined } from "@ant-design/icons"
import { Button, Checkbox, Select, Table, message } from "antd"
import { ColumnsType } from "antd/es/table"
import { useEffect, useState } from "react"
import { ShowInSelect } from "src/interfaces/requests/ShowInSelect"
import { useAppDispatch, useAppSelector } from "src/state/hooks/redux"
import { changeVersionForNewUsers, deleteVersion, getUsersListThunk, getVersionsListThunk, multipleChangeVersionsThunk, showInSelect } from "src/state/reducers/app.slice"
import { ControlBlock, ControlItem, Title, VersionsPageWrapper } from "./styles"

export const VersionsPage = () => {
	const dispatch = useAppDispatch()
	const { users, versions } = useAppSelector(state => state.appReducer)

	const [abCount, setABcount] = useState<number>()
	const [multipleChangeVersionFrom, setMultipleChangeVersionFrom] = useState<string[]>()
	const [multipleChangeVersionTo, setMultipleChangeVersionTo] = useState<string>()

	useEffect(() => {
		dispatch(getUsersListThunk())
		dispatch(getVersionsListThunk())
	}, [])

	const handleChangeVersionForNewUsers = (buildVersion: string) => {
		dispatch(changeVersionForNewUsers({ buildVersion }))
	}
	const handleChangeShowInSelect = (dto: ShowInSelect.Request) => {
		dispatch(showInSelect(dto))
		dispatch(getVersionsListThunk())
	}

	const handleSelectMultipleVersionFrom = (versions: string[]) => {
		setMultipleChangeVersionFrom(versions)
	}
	const handleSelectMultipleVersionTo = (version: string) => {
		setMultipleChangeVersionTo(version)
	}
	const handleSelectMultipleVersionSubmit = () => {
		if (!multipleChangeVersionFrom.length || !multipleChangeVersionTo) {
			message.warning("Версии не выбраны")
			return
		}
		dispatch(multipleChangeVersionsThunk({ from: multipleChangeVersionFrom, to: multipleChangeVersionTo }))
		setMultipleChangeVersionFrom(undefined)
		setMultipleChangeVersionTo(undefined)
	}

	const handleDeleteVersion = (versionName: string) => {
		if (window.confirm(`Удалить билд #${versionName}?`)) {
			dispatch(deleteVersion({ buildVersion: versionName }))
			dispatch(getVersionsListThunk())
		}
	}

	const handleChangeABSlider = (count: number) => {
		setABcount(count)
	}

	if (versions === null) {
		return <>Нет версий</>
	}

	let key = 1
	const getKey = () => key++
	const dataSource = versions.map((version, index) => ({
		key: index,
		id: version.id,
		version: version.name,
		description: version.description,
		created_at: new Date(version.created_at).toLocaleString(),
		show_in_select: version.showInSelect,
		users_count: `${version.users?.length} (${((version?.users?.length / users?.length) * 100).toFixed(1)}%)`,
	}))

	const columns: ColumnsType<(typeof dataSource)[0]> = [
		{
			title: "Version",
			dataIndex: "version",
			key: getKey(),
			align: "center",
		},
		{
			title: "Users usage",
			dataIndex: "users_count",
			key: getKey(),
			align: "center",
		},
		{
			title: "Show in select",
			dataIndex: "show_in_select",
			key: getKey(),
			align: "center",
			width: "130px",
			render: (value, record) => (
				<Checkbox
					style={{ fontSize: "20px" }}
					checked={value}
					onChange={e => handleChangeShowInSelect({ buildVersion: record.version, mode: !!e.target.checked })}
				></Checkbox>
			),
		},
		{
			title: "Delete",
			dataIndex: "delete",
			key: getKey(),
			align: "center",
			width: "100px",
			render: (value, record) => <DeleteOutlined style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => handleDeleteVersion(record.version)} />,
		},
		{
			title: "Date created (UTC)",
			dataIndex: "created_at",
			key: getKey(),
			align: "center",
			width: "250px",
		},
	]

	dataSource.sort((a, b) => b.id - a.id)

	return (
		<VersionsPageWrapper>
			<ControlBlock>
				<ControlItem>
					<Title>Версия для новых пользователей</Title>
					<Select
						value={versions.find(version => !!version?.forNewUsers)?.name || null}
						onChange={value => handleChangeVersionForNewUsers(value)}
						options={
							versions?.length &&
							versions
								.slice()
								.filter(version => version.showInSelect)
								.sort((a, b) => b?.id - a?.id)
								.map(version => ({ value: version?.name, title: version?.description }))
						}
						loading={false}
						style={{ width: "100px" }}
					/>
				</ControlItem>
				<ControlItem>
					Пользователей с версии
					<Select
						mode="multiple"
						style={{ minWidth: "150px", maxWidth: "350px" }}
						onChange={handleSelectMultipleVersionFrom}
						value={multipleChangeVersionFrom}
						options={
							versions?.length &&
							versions
								.slice()
								.filter(version => version.users.length > 0)
								.sort((a, b) => b?.id - a?.id)
								.map(version => ({ value: version?.name, label: version?.name + ` - ${version.users.length} users` }))
						}
					/>
					на версию
					<Select
						style={{ minWidth: "150px", maxWidth: "350px" }}
						onChange={handleSelectMultipleVersionTo}
						value={multipleChangeVersionTo}
						options={
							versions?.length &&
							versions
								.slice()
								.filter(version => version.showInSelect)
								.sort((a, b) => b?.id - a?.id)
								.map(version => ({ value: version?.name, title: version?.description }))
						}
					/>
					<Button type="primary" onClick={handleSelectMultipleVersionSubmit}>
						Перенести
					</Button>
				</ControlItem>
				{/* <Maket>
						<Select
							options={
								versions?.length &&
								versions
									.slice()
									.filter(version => version.showInSelect)
									.sort((a, b) => b?.id - a?.id)
									.map(version => ({ value: version?.name, title: version?.description }))
							}
							loading={false}
							style={{ width: "100px" }}
						/>
						<Counter>{abCount ? `${abCount} (${((abCount / users?.length) * 100).toFixed(1)}%)` : "0 (0%)"}</Counter>
						<Slider
							trackStyle={{ backgroundColor: "orange" }}
							railStyle={{ backgroundColor: "purple" }}
							max={users.length}
							tooltip={{ open: false }}
							onChange={handleChangeABSlider}
							value={abCount}
						/>
						<Counter>{abCount ? `${users?.length - abCount} (${(((users?.length - abCount) / users?.length) * 100).toFixed(1)}%)` : "0 (0%)"}</Counter>
						<Select
							options={
								versions?.length &&
								versions
									.slice()
									.filter(version => version.showInSelect)
									.sort((a, b) => b?.id - a?.id)
									.map(version => ({ value: version?.name, title: version?.description }))
							}
							loading={false}
							style={{ width: "100px" }}
						/>
						<Button type="primary" onClick={() => message.info("Макет")}>Применить</Button>
				</Maket> */}
			</ControlBlock>
			{/* <Table dataSource={dataSource} columns={columns} style={{ width: "auto" }} pagination={false} scroll={{ y: "45vh" }} /> */}
			<Table
				dataSource={dataSource}
				columns={columns}
				expandable={{
					expandedRowRender: record => <p style={{ margin: 0, whiteSpace: "pre-line", textAlign: "left" }}>{record.description}</p>,
					rowExpandable: record => record.version !== "Not Expandable",
					expandRowByClick: true,
				}}
				style={{ width: "auto" }}
				pagination={false}
			/>
		</VersionsPageWrapper>
	)
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { message } from "antd"
import axios, { AxiosError, AxiosResponse } from "axios"
import { ChangeUserGameBuild } from "src/interfaces/requests/changeUserGameBuild"
import { ChangeVersionForNewUsers } from "src/interfaces/requests/changeVersionForNewVersion"
import { DeleteVersion } from "src/interfaces/requests/deleteVersion"
import { Login } from "src/interfaces/requests/login"
import { ShowInSelect } from "src/interfaces/requests/ShowInSelect"
import { IGameVersion } from "../../interfaces/models/build.interface"
import { IUser } from "../../interfaces/models/user.interface"
import { RootState } from "../store"
import { MultipleChangeUserGameBuild } from "src/interfaces/requests/multipleChangeUserGameBuild"

export const getPasswordFromSS = () => {
	return sessionStorage.getItem("pass")
}
export const setPasswordFromSS = (pass: string) => {
	return sessionStorage.setItem("pass", pass)
}
export const removePasswordFromSS = () => {
	return sessionStorage.removeItem("pass")
}

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	// headers: { Accept: "application/json", "Content-Type": "application/json" },
	withCredentials: true,
})

export const changeUserGameVersionThunk = createAsyncThunk(
	"changeUserGameVersion",
	async ({ buildVersion, userId }: ChangeUserGameBuild.Request, { getState, rejectWithValue }) => {
		try {
			const res = await axiosInstance
				.post<{}, AxiosResponse<IUser[]>, ChangeUserGameBuild.Request>(
					ChangeUserGameBuild.path,
					{ buildVersion, userId },
					{ headers: { Authorization: `Bearer ${getPasswordFromSS()}` } }
				)
				.then(res => res.data)
			return res
		} catch (e) {
			if (axios.isAxiosError(e)) {
				return rejectWithValue(e)
			}
			return
		}
	}
)

export const multipleChangeVersionsThunk = createAsyncThunk(
	"multipleChangeVersionsThunk",
	async ({ from, to }: MultipleChangeUserGameBuild.Request, { getState, rejectWithValue, dispatch }) => {
		try {
			const res = await axiosInstance
				.post<{}, AxiosResponse<IUser[]>, MultipleChangeUserGameBuild.Request>(
					MultipleChangeUserGameBuild.path,
					{ from, to },
					{ headers: { Authorization: `Bearer ${getPasswordFromSS()}` } }
				)
				.then(res => res.data)
			dispatch(getVersionsListThunk())
			return res
		} catch (e) {
			if (axios.isAxiosError(e)) {
				return rejectWithValue(e)
			}
			return
		}
	}
)

export const changeVersionForNewUsers = createAsyncThunk("changeVersionForNewUsers", async (payload: ChangeVersionForNewUsers.Request, { getState, rejectWithValue, dispatch }) => {
	try {
		const res = await axiosInstance
			.post<{}, AxiosResponse<ChangeVersionForNewUsers.Response>, ChangeVersionForNewUsers.Request>(ChangeVersionForNewUsers.path, payload, {
				headers: { Authorization: `Bearer ${getPasswordFromSS()}` },
			})
			.then(res => res.data)
		// Получение версий
		dispatch(getVersionsListThunk())
		return res
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e.message)
		}
		return
	}
})

export const showInSelect = createAsyncThunk("showInSelect", async (payload: ShowInSelect.Request, { getState, rejectWithValue, dispatch }) => {
	try {
		const res = await axiosInstance
			.post<{}, AxiosResponse, ShowInSelect.Request>(ShowInSelect.path, payload, {
				headers: { Authorization: `Bearer ${getPasswordFromSS()}` },
			})
			.then(res => res.data)
		// Получение версий
		dispatch(getVersionsListThunk())
		return res
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e.message)
		}
		return
	}
})

export const deleteVersion = createAsyncThunk("deleteVersion", async (payload: DeleteVersion.Request, { getState, rejectWithValue, dispatch }) => {
	try {
		const res = await axiosInstance
			.delete<{}, AxiosResponse, DeleteVersion.Request>(DeleteVersion.path, {
				headers: { Authorization: `Bearer ${getPasswordFromSS()}` },
				params: { ...payload },
			})
			.then(res => res.data)
		// Получение версий
		dispatch(getVersionsListThunk())
		return res
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e)
		}
		return
	}
})

export const getVersionsListThunk: any = createAsyncThunk("getVersions", async (f, { getState, rejectWithValue }) => {
	try {
		const res = await axiosInstance
			.get<AxiosResponse<IGameVersion[]>>("/game-build-storage/all_builds", { headers: { Authorization: `Bearer ${getPasswordFromSS()}` } })
			.then(res => res.data)
		return res
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e.message)
		}
		return
	}
})
export const getUsersListThunk: any = createAsyncThunk("getUsers", async (f, { getState, rejectWithValue }) => {
	try {
		const res = await axiosInstance.get<AxiosResponse<IUser[]>>("/admins/users", { headers: { Authorization: `Bearer ${getPasswordFromSS()}` } }).then(res => res.data)
		return res
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e.message)
		}
		return
	}
})
export const loginThunk: any = createAsyncThunk("login", async (f, { getState, rejectWithValue }) => {
	try {
		let pass
		const state = getState() as RootState
		const { email, password: passwordFromState } = state.appReducer.auth
		// if (!login || !password) return
		pass = passwordFromState || getPasswordFromSS()
		const res = await axiosInstance.post<{}, AxiosResponse<Login.ResponseSuccess>>("/admins/auth", {}, { headers: { Authorization: `Bearer ${pass}` } }).then(res => res.data)
		return res
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e.message)
		}
		return
	}
})

interface IState {
	versions: IGameVersion[]
	users: IUser[]
	adminInfo: Login.ResponseSuccess | null
	auth: {
		email: null | string
		password: null | string
	}
	isLogined: boolean
	isLoadingAuth: boolean
}

const initialState: IState = {
	versions: [],
	users: [],
	auth: {
		email: null,
		password: null,
	},
	isLogined: false,
	isLoadingAuth: false,
	adminInfo: null,
}

export const AppSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setEmail(state, action: PayloadAction<string>) {
			state.auth.email = action.payload
		},
		setPassword(state, action: PayloadAction<string>) {
			state.auth.password = action.payload
			setPasswordFromSS(action.payload)
		},
	},
	extraReducers: {
		[loginThunk.pending.type]: state => {
			state.isLoadingAuth = true
		},
		[loginThunk.rejected.type]: (state, action: PayloadAction<AxiosError>) => {
			state.isLogined = false
			state.isLoadingAuth = false
			removePasswordFromSS()
			message.error("Not valid auth data")
		},
		[loginThunk.fulfilled.type]: (state, action: PayloadAction<Login.ResponseSuccess>) => {
			state.isLogined = true
			state.isLoadingAuth = false
			state.adminInfo = action.payload
		},

		[getUsersListThunk.pending.type]: state => {},
		[getUsersListThunk.rejected.type]: (state, action: PayloadAction<AxiosError>) => {},
		[getUsersListThunk.fulfilled.type]: (state, action: PayloadAction<IUser[]>) => {
			state.users = action.payload
		},

		[getVersionsListThunk.pending.type]: state => {},
		[getVersionsListThunk.rejected.type]: (state, action: PayloadAction<AxiosError>) => {},
		[getVersionsListThunk.fulfilled.type]: (state, action: PayloadAction<IGameVersion[]>) => {
			state.versions = action.payload
		},

		[changeUserGameVersionThunk.pending.type]: state => {},
		[changeUserGameVersionThunk.rejected.type]: (state, action: PayloadAction<AxiosError<ChangeUserGameBuild.ResponseError>>) => {
			console.log(action?.payload?.response)
			if (action?.payload?.response?.data?.message) {
				message.error(action?.payload?.response?.data?.message)
			}
		},
		[changeUserGameVersionThunk.fulfilled.type]: (state, action: PayloadAction<IGameVersion[]>) => {
			message.success("Success change")
		},

		[multipleChangeVersionsThunk.pending.type]: state => {},
		[multipleChangeVersionsThunk.rejected.type]: (state, action: PayloadAction<AxiosError<MultipleChangeUserGameBuild.ResponseError>>) => {
			console.log(action?.payload?.response)
			if (action?.payload?.response?.data?.message) {
				message.error(action?.payload?.response?.data?.message)
			}
		},
		[multipleChangeVersionsThunk.fulfilled.type]: (state, action: PayloadAction<MultipleChangeUserGameBuild.ResponseSuccess>) => {
			const { countChangedUsers } = action.payload
			if (countChangedUsers === 0) {
				message.info(`Пользователи не найдены`)
			} else {
				message.success(`Версии изменены`)
			}
		},

		[changeVersionForNewUsers.pending.type]: state => {},
		[changeVersionForNewUsers.rejected.type]: (state, action: PayloadAction<AxiosError<ChangeVersionForNewUsers.ResponseError>>) => {
			console.log(action?.payload?.response)
			if (action?.payload?.response?.data?.message) {
				message.error(action?.payload?.response?.data?.message)
			}
		},
		[changeVersionForNewUsers.fulfilled.type]: (state, action: PayloadAction) => {
			message.success("Success change")
		},

		[deleteVersion.pending.type]: state => {
			message.info("Request sended.")
		},
		[deleteVersion.rejected.type]: (state, action: PayloadAction<AxiosError<DeleteVersion.ResponseError>>) => {
			if (action?.payload?.response?.data?.message) {
				message.error(action?.payload?.response?.data?.message)
			} else {
				message.error("Not deleted. Error.")
			}
		},
		[deleteVersion.fulfilled.type]: (state, action: PayloadAction) => {
			message.success("Success deleted")
		},
	},
})

export default AppSlice
